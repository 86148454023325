import React, { createContext } from "react";

export const SidebarContext = createContext();

export const SidebarContextProvider = ({ children, isSidebar }) => {
  return (
    <SidebarContext.Provider value={isSidebar}>
      {children}
    </SidebarContext.Provider>
  );
};
