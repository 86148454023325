import React, { useState } from "react";
import $ from "jquery";

const useSidebar = () => {
  const [visible, setVisible] = useState(false);

  /* Burger menu */
  const $burgerIcon = $("#burger-menu-icon");

  const handleBurgerClick = () => {
    $burgerIcon.attr("aria-expanded", true);
    setVisible(true);
    $burgerIcon.off({ click: handleBurgerClick });
    $burgerIcon.on({ click: handleBurgerCloseClick });
  };

  const handleBurgerCloseClick = () => {
    $burgerIcon.attr("aria-expanded", false);
    setVisible(false);
    $burgerIcon.on({ click: handleBurgerClick });
    $burgerIcon.off({ click: handleBurgerCloseClick });
  };

  $burgerIcon.on({ click: handleBurgerClick });

  return [visible, setVisible];
};

export default useSidebar;
