import React, { useContext } from "react";
import { Accordion } from "semantic-ui-react";
import { SidebarContext, BucketAggregationContextProvider } from "../contexts";

export const BucketAggregationElement = ({ title, containerCmp, agg }) => {
  const isSidebarMenu = useContext(SidebarContext);
  
  return (
    <div className={isSidebarMenu ? "item" : "facets-item"}>
      <Accordion.Title as={"a"} className="accordion-title">
        {(isSidebarMenu && (
          <>
            {title}
            <i className="dropdown icon" style={{ textDecoration: "none" }}></i>
          </>
        )) || (
          <p>
            <span>{title}</span>
            <i className="middle aligned facet-dropdown-caret">▼</i>
          </p>
        )}
      </Accordion.Title>
      <Accordion.Content className="facet-accordion-content">
        <BucketAggregationContextProvider agg={agg}>
          {containerCmp}
        </BucketAggregationContextProvider>
      </Accordion.Content>
    </div>
  );
};
