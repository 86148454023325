import React, { useContext } from "react";
import { BucketAggregation } from "react-searchkit";
import { Accordion } from "semantic-ui-react";
import { SidebarContext } from "../contexts";

export const SearchAppFacets = ({ aggs, appName }) => {
  const isSidebarMenu = useContext(SidebarContext);

  return (
    <>
      {(!isSidebarMenu && (
        <Accordion exclusive={false} className="facets-accordion">
          {aggs.map((agg) => (
            <BucketAggregation
              key={agg.aggName}
              title={agg.title}
              agg={agg}
            />
          ))}
        </Accordion>
      )) ||
        aggs.map((agg) => (
          <BucketAggregation
            key={agg.aggName}
            title={agg.title}
            agg={agg}
          />
        ))}
    </>
  );
};
