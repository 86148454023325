import {
  parseSearchAppConfigs,
  createSearchAppsInit
 } from '@js/oarepo_ui'
import {
  EmptyResultsElement,
  SearchBarElement,
  ResultsListItemWithState,
  SearchAppResultOptions,
  CountElement,
  SearchAppResults,
  SearchAppFacets,
  BucketAggregationElement,
  BucketAggregationContainerElement,
  BucketAggregationValuesElement,
  SearchAppLayout,
  ResultsPerPageElement,
  ActiveFiltersElement,
} from './components'

const [searchAppConfig, ...otherSearchAppConfigs] = parseSearchAppConfigs()

const { overridableIdPrefix } = searchAppConfig

export const componentOverrides = {
  [`${overridableIdPrefix}.ActiveFilters.element`]: ActiveFiltersElement,
  [`${overridableIdPrefix}.BucketAggregation.element`]: BucketAggregationElement,
  [`${overridableIdPrefix}.BucketAggregationContainer.element`]: BucketAggregationContainerElement,
  [`${overridableIdPrefix}.BucketAggregationValues.element`]: BucketAggregationValuesElement,
  [`${overridableIdPrefix}.Count.element`]: CountElement,
  [`${overridableIdPrefix}.EmptyResults.element`]: EmptyResultsElement,
  [`${overridableIdPrefix}.ResultsGrid.item`]: ResultsListItemWithState,
  [`${overridableIdPrefix}.ResultsList.item`]: ResultsListItemWithState,
  [`${overridableIdPrefix}.ResultsPerPage.element`]: ResultsPerPageElement,
  [`${overridableIdPrefix}.SearchApp.facets`]: SearchAppFacets,
  [`${overridableIdPrefix}.SearchApp.layout`]: SearchAppLayout,
  [`${overridableIdPrefix}.SearchApp.resultOptions`]: SearchAppResultOptions,
  [`${overridableIdPrefix}.SearchApp.results`]: SearchAppResults,
  [`${overridableIdPrefix}.SearchBar.element`]: SearchBarElement,
}

createSearchAppsInit({ componentOverrides });
