import React, { useContext } from "react";

import { ContribBucketAggregationValuesElement } from "./facets";

import { BucketAggregationContext } from "../contexts";

export const BucketAggregationValuesElement = ({ bucket, ...rest }) => {
  const agg = useContext(BucketAggregationContext);
  const key = `${agg.aggName ? agg.aggName + '____' : ''}${bucket.key.toString()}`;

  return (
    <ContribBucketAggregationValuesElement
      bucket={{ ...bucket, key: key }}
      {...rest}
    />
  );
};
