import React, { createContext } from "react";

export const BucketAggregationContext = createContext();

export const BucketAggregationContextProvider = ({ children, agg }) => {
  return (
    <BucketAggregationContext.Provider value={agg}>
      {children}
    </BucketAggregationContext.Provider>
  );
};
