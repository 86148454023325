import React, { Component } from "react";
import PropTypes from "prop-types";
import { i18next } from "@translations/i18next";
import { Input } from "semantic-ui-react";
import { withState } from "react-searchkit";

class Element extends Component {
  componentDidMount() {
    const { autofocus } = this.props;
    if (autofocus && this.focusInput) {
      this.focusInput.focus();
    }
  }

  render() {
    const {
      actionProps,
      onBtnSearchClick,
      onInputChange,
      onKeyPress,
      placeholder,
      queryString,
      uiProps,
    } = this.props;

    return (
      <Input
        action={{
          content: "Search",
          onClick: onBtnSearchClick,
          ...actionProps,
        }}
        fluid
        {...uiProps}
        placeholder={placeholder || "Search..."}
        onChange={(_, { value }) => {
          onInputChange(value);
        }}
        value={queryString}
        onKeyPress={onKeyPress}
        ref={(input) => {
          this.focusInput = input;
        }}
      />
    );
  }
}

Element.propTypes = {
  actionProps: PropTypes.object,
  autofocus: PropTypes.bool,
  onBtnSearchClick: PropTypes.func,
  onInputChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  queryString: PropTypes.string,
  uiProps: PropTypes.object,
};

Element.defaultProps = {
  actionProps: null,
  autofocus: false,
  onBtnSearchClick: null,
  onInputChange: null,
  onKeyPress: null,
  placeholder: "",
  queryString: "",
  uiProps: null,
};

const SearchBarElementWithState = withState(Element);

export const SearchBarElement = ({
  queryString,
  onBtnSearchClick,
  onInputChange,
  onKeyPress,
  uiProps,
}) => {
  return (
    <SearchBarElementWithState
      queryString={queryString}
      onBtnSearchClick={onBtnSearchClick}
      onInputChange={onInputChange}
      onKeyPress={onKeyPress}
      placeholder={`${i18next.t("Search")}...`}
      actionProps={{ 
        content: i18next.t("Search"),
        className: "right-floated search-button",
        "aria-label": i18next.t("Search")
      }}
      uiProps={uiProps}
      autofocus={true}
    />
  );
};
