import React from "react";
import { PropTypes } from "prop-types";
import { i18next } from "@translations/i18next";

export const CountElement = ({ totalResults }) => {
  return <span>{totalResults}</span>;
};

export const SearchAppResultOptions = ({
  currentResultsState,
}) => {
  const { total } = currentResultsState.data;

  return (
    <div className="listing-search-results">
      <span className="text-big">{i18next.t("Results")}:</span>
      {" "}
      <CountElement totalResults={total} /> {i18next.t('records-found-text', { count: total })}
    </div>
  );
};

SearchAppResultOptions.propTypes = {
  currentResultsState: PropTypes.object.isRequired,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      sortBy: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  paginationOptions: PropTypes.shape({
    defaultValue: PropTypes.number,
    resultsPerPage: PropTypes.array,
  }),
  layoutOptions: PropTypes.object,
};
