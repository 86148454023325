import React from "react";
import { useMediaQuery } from 'react-responsive'

export const Desktop = ({ handleMediaQueryChange,  children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1300 }, undefined, handleMediaQueryChange)
  return isDesktop ? children : null
}

export const Tablet = ({ handleMediaQueryChange, children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1299 }, undefined, handleMediaQueryChange)
  return isTablet ? children : null
}

export const Mobile = ({ handleMediaQueryChange, children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 }, undefined, handleMediaQueryChange)
  return isMobile ? children : null
}

export const Default = ({ handleMediaQueryChange, children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 }, undefined, handleMediaQueryChange)
  return isNotMobile ? children : null
}