import React, {
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import PropTypes from "prop-types";
import { useLocation, useSidebar } from "../hooks";
import { SidebarContextProvider } from "../contexts";
import { Mobile } from "./ResponsiveComponents";
import _isEmpty from "lodash/isEmpty";
import _isNil from "lodash/isNil";
import { withState, ActiveFilters } from "react-searchkit";
import { i18next } from "@translations/i18next";
import {
  SearchAppFacets,
  SearchAppResultsPane,
  SearchBar,
  SearchConfigurationContext,
} from "@js/invenio_search_ui/components";
import { ResultOptions } from "@js/invenio_search_ui/components/Results";
import {
  Header,
  Grid,
  Item,
  Divider,
  Input,
  Sidebar,
  Menu,
  Segment
} from "semantic-ui-react";

const ResultOptionsWithState = withState(ResultOptions);

const getCurrentSortByOption = (q) => new URLSearchParams(q).get("sort");

const initAccordion = () => {
  $(".ui.accordion").accordion({
    exclusive: false,
  });
};

export const SearchAppLayout = ({ config }) => {
  const { appName, buildUID } = useContext(SearchConfigurationContext);
  const { search: queryParams } = useLocation();
  const [visible, setVisible] = useSidebar();
  const [sortByOption, setSortByOption] = useState(getCurrentSortByOption());

  const displayedFacets = config.aggs;

  useLayoutEffect(initAccordion, []);

  useEffect(
    () => setSortByOption(getCurrentSortByOption(queryParams)),
    [queryParams]
  );

  return (
    <>
      <Sidebar.Pushable>
        <Mobile
          handleMediaQueryChange={(matches) =>
            matches ? initAccordion() : () => { }
          }
        >
          <Sidebar
            as={Menu}
            id="sidebar-menu"
            visible={visible}
            onHide={() => setVisible(false)}
            vertical
            direction="left"
            animation="push"
            className="accordion"
          >
            {displayedFacets && (
              <SidebarContextProvider isSidebar={true}>
                <SearchAppFacets
                  aggs={displayedFacets}
                  appName={appName}
                  buildUID={buildUID}
                />
              </SidebarContextProvider>
            )}
          </Sidebar>
        </Mobile>
        <Sidebar.Pusher>
          <Grid padded className="main-heading-container">
            <Grid.Column as={Grid} padded>
              <Grid.Row only="computer tablet">
                <Item.Group relaxed>
                  <Item>
                    <Item.Image
                      id="uct-header-logo"
                      size="tiny"
                      src="/static/images/logoVSCHT.svg"
                      alt={i18next.t("UCT Prague logo")}
                    />
                    <Item.Content>
                      <Item.Header as="h1" id="uct-header-title-text">
                        <span className="heading-text">
                          {i18next.t("Theses repository")}
                          <br />
                          {i18next.t("UCT Prague")}
                        </span>
                      </Item.Header>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Grid.Row>
              <Grid.Row only="mobile" centered>
                <Header as="h1" className="heading-text" textAlign="center">
                  <span className="heading-text">
                    {i18next.t("Theses repository")}
                    <br />
                    {i18next.t("UCT Prague")}
                  </span>
                </Header>
              </Grid.Row>
            </Grid.Column>
          </Grid>
          <Grid as={Segment} basic id="main-content" doubling stackable>
            <Grid.Column
              as="aside"
              width={4}
              floated="left"
              className="facets-container"
              only="computer tablet"
            >
              {displayedFacets && (
                <SidebarContextProvider isSidebar={false}>
                  <SearchAppFacets
                    aggs={displayedFacets}
                    appName={appName}
                    buildUID={buildUID}
                  />
                </SidebarContextProvider>
              )}
            </Grid.Column>
            <Grid.Column
              computer={12}
              tablet={12}
              mobile={16}
              className="listing-container"
            >
              <Grid.Row only="computer tablet">
                <p className="listing-heading">
                  {i18next.t("search-listing-heading")}
                </p>
              </Grid.Row>
              <Grid>
                <Grid.Column as={Grid} padded computer={13} tablet={13} mobile={16}>
                  <Grid.Row columns={1}>
                    {displayedFacets && <ActiveFilters />}
                    <Input
                      size="huge"
                      action
                      fluid
                      className="main-search-container"
                    >
                      <SearchBar buildUID={buildUID} appName={appName} />
                    </Input>
                  </Grid.Row>
                  <Grid.Row
                    as="a"
                    only="mobile"
                    href={sortByOption ? `/theses/?sort=${sortByOption}` : "/theses"}
                    className="search-all-link"
                  >
                    {i18next.t("all records")}
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  verticalAlign="bottom"
                  only="computer tablet"
                  className="search-all-link-container"
                >
                  <a
                    href={sortByOption ? `/theses/?sort=${sortByOption}` : "/theses"}
                    className="search-all-link"
                  >
                    {i18next.t("all records")}
                  </a>
                </Grid.Column>
              </Grid>
              <Divider hidden />
              <ResultOptionsWithState />
              <Divider hidden />
              <SearchAppResultsPane
                layoutOptions={config.layoutOptions}
                appName={appName}
                buildUID={buildUID}
              />
            </Grid.Column>
          </Grid>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
};

SearchAppLayout.propTypes = {
  config: PropTypes.shape({
    searchApi: PropTypes.object.isRequired, // same as ReactSearchKit.searchApi
    initialQueryState: PropTypes.shape({
      queryString: PropTypes.string,
      sortBy: PropTypes.string,
      sortOrder: PropTypes.string,
      page: PropTypes.number,
      size: PropTypes.number,
      hiddenParams: PropTypes.array,
      layout: PropTypes.oneOf(["list", "grid"]),
    }),
  }),
};
