import React, { useContext } from "react";
import PropTypes from "prop-types";
import Overridable from "react-overridable";
import { i18next } from "@translations/i18next";

import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _filter from "lodash/filter";
import _truncate from "lodash/truncate";

import { withState, buildUID } from "react-searchkit";
import { sanitize } from "dompurify";
import { SearchConfigurationContext } from "@js/invenio_search_ui/components";

const ItemHeader = ({
  titleHtml,
  searchUrl,
  selfLink,
  className = "listing-item-title",
}) => {
  const viewLink = new URL(
    selfLink,
    new URL(searchUrl, window.location.origin)
  );
  return (
    <a href={viewLink}>
      <p className={className} dangerouslySetInnerHTML={{ __html: titleHtml }} />
    </a>
  );
};

export const ResultsListItemComponent = ({
  currentQueryState,
  result,
  appName,
  ...rest
}) => {
  const searchAppConfig = useContext(SearchConfigurationContext);

  const title = _get(result, "metadata.title", null);
  const additionalTitles = _get(result, "metadata.additionalTitles", [])
    .map((titleObj) => _get(titleObj, "title.value"))
    .filter((title) => title);
  const creators = _get(result, "metadata.creators", [])
    .map((creatorObj) => _get(creatorObj, "fullName"))
    .filter((creator) => creator);
  const abstracts = _get(result, "metadata.abstract", [])
    .map((abstractObj) =>
      _truncate(_get(abstractObj, "value"), { length: 124 })
    )
    .filter((abstract) => abstract);
  const thesisType = _get(result, "metadata.resourceType.title").toUpperCase();
  const yearDefended = _get(
    result,
    "metadata.thesis.dateDefended"
  )?.slice(-4) ?? i18next.t("not defended").toUpperCase();
  const department = _get(result, "metadata.thesis.degreeGrantors.0.title");

  const renderedMainTitle = title
    ? title
    : additionalTitles
      ? additionalTitles[0]
      : "<unknown title>";

  const filteredAdditionalTitles = additionalTitles.filter((title) => title !== renderedMainTitle);

  return (
    <article key={result.id} className="listing-item">
      <ItemHeader
        titleHtml={sanitize(renderedMainTitle)}
        searchUrl={searchAppConfig.ui_endpoint}
        selfLink={result.links.self_html}
      />
      {!_isEmpty(filteredAdditionalTitles) &&
        filteredAdditionalTitles.map((title, key) =>
          <ItemHeader
            key={key}
            titleHtml={sanitize(title)}
            searchUrl={searchAppConfig.ui_endpoint}
            selfLink={result.links.self_html}
            className="listing-item-title-en"
          />
        )}
      {creators &&
        creators.map((creator, key) => (
          <p key={key} className="listing-item-creator">
            {creator}
          </p>
        ))}
      <p className="listing-item-defended">
        {thesisType ? <><span className="text-big">{thesisType}</span>&nbsp;&nbsp;|&nbsp;&nbsp;</> : null}
        <span className="text-big">{yearDefended}</span>
        {department ? <>&nbsp;&nbsp;|&nbsp;&nbsp;{department}</> : null}
      </p>
      {abstracts &&
        abstracts.map((abstract, key) => {
          const sanitizedAbstract = sanitize(abstract);
          return (
            <p key={key} className="listing-item-abstract" dangerouslySetInnerHTML={{ __html: sanitizedAbstract }} />
          );
        })}
    </article>
  );
};

ResultsListItemComponent.propTypes = {
  currentQueryState: PropTypes.object,
  result: PropTypes.object.isRequired,
  appName: PropTypes.string,
};

ResultsListItemComponent.defaultProps = {
  currentQueryState: null,
  appName: "",
};

export const ResultsListItem = (props) => {
  return (
    <ResultsListItemComponent {...props} />
  );
};

ResultsListItem.propTypes = {
  currentQueryState: PropTypes.object,
  result: PropTypes.object.isRequired,
  appName: PropTypes.string,
};

ResultsListItem.defaultProps = {
  currentQueryState: null,
  appName: "",
};

export const ResultsListItemWithState = withState(
  ({ currentQueryState, updateQueryState, result, appName }) => (
    <ResultsListItem
      currentQueryState={currentQueryState}
      updateQueryState={updateQueryState}
      result={result}
      appName={appName}
    />
  )
);

ResultsListItemWithState.propTypes = {
  currentQueryState: PropTypes.object,
  result: PropTypes.object.isRequired,
};

ResultsListItemWithState.defaultProps = {
  currentQueryState: null,
};
